<template>
	<div>
		<!-- PC端 -->
		<template v-if="$store.state.platform == 'pc'">
			<Header></Header>
			<div style="height: 134px;"></div>
			<BreadCrumb></BreadCrumb>
			<router-view />
			<Footer></Footer>
		</template>
		<!-- 移动端 -->
		<template v-else>
			<HeaderMob></HeaderMob>
			<div style="height: 55px;"></div>
			<transition :name="transitionName"><router-view /></transition>
			<FooterMob></FooterMob>
		</template>
	</div>
</template>

<script>
import Header from '../components/Base/header';
import HeaderMob from '../components/Base/headerMob';
import Footer from '../components/Base/footer';
import FooterMob from '../components/Base/footerMob';
import BreadCrumb from '../components/Base/breadcrumb.vue'
export default {
	name: 'Base',
	components: {
		Header,
		HeaderMob,
		Footer,
		FooterMob,
		BreadCrumb
	},
	watch: {
		$route(to, from) {
			// 切换动画
			let isBack = this.$router.isBack; // 监听路由变化时的状态为前进还是后退
			if (isBack) {
				this.transitionName = 'slide-right';
			} else {
				this.transitionName = 'slide-left';
			}
			this.$router.isBack = false;
		}
	},
	data() {
		return {
			transitionName: 'slide-right'
		};
	},
	created() {},
	methods: {}
};
</script>

<style lang="less" scoped>
.slide-left-enter-active,.slide-right-enter-active {
	transition: all 0.377s ease;
	
}
.slide-left-enter {
	opacity: 0;
	 transform: translate3d(100%, 0, 0);
}
.slide-right-enter {
	opacity: 0;
	 transform: translate3d(-100%, 0, 0);
}
</style>
